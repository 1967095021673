#pre-engagement {
    font-family: 'Roboto', sans-serif;
    display:"flex";
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}
#notice {
    display: flex;
    flex-direction: column;
    align-items: center;
}
[id^="form"] {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
#form_2{
 margin-top: 5px;   
}

#form_2 input {
    background-color: unset;
}

#secondAnnouncement {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    text-align: center;
}
/* [id$="field"] {
    
    width: auto;
    height: 35px;
    margin: auto;
    padding: auto; 
    border-color: 2px solid red;
    border-radius: 15px;
    background-color: red;
  } */

.inputInfo {
    align-self: normal;
    font-weight: 800;
    margin-left: 1.75rem;
}

.inputInfo > sup{
    color: red;
}

.field > input, #select-main-contact-reason,#select-sub-contact-reason {
    width: 190px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: auto; 
    border-color: 10px solid red;
    border-radius: 4px;
    -webkit-appearance:'none';
}
/* #select-sub-contact-reason{
   
    margin-top: 0px;
    padding-top: 0px;
} */
/* #select-main-contact-field {
    width: 150px;
    background-color: red;
    height: 35px;
    margin: auto;
    padding: auto; 
    border-color: 2px solid red;
    border-radius: 15px;

} */
/* #select-mainContact {
} */

#submit-button {
    width: 50%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #8cd341;
    color: #fff;
    text-transform: initial;
}

.myHelperText {
    margin-top: -4px !important;
    padding: 0px;
    color: red !important;
    align-self: baseline;
    margin-left: 1.75rem !important;
    margin-bottom: 4px !important;
}

/* Chrome, Safari, Edge, Opera */
input#booking-number-field::-webkit-outer-spin-button,
input#booking-number-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input#booking-number-field[type=number] {
  -moz-appearance: textfield;
}

.MessageCanvasTrayContent {
    text-align: center;
}

[data-test="StartNewChatAreaButton"] {
    background: #8cd341 !important;
    letter-spacing: 0px !important;
    font-family: 'Roboto', sans-serif !important;
}

.Twilio-MainContainer {
    min-height: 548px !important;
    height: auto !important; /* Override the height */
}
  